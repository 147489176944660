import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react"
import { CART_IDENTIFYER, changeQuantityOfItem, getCartItems, removeFromCart } from "../../functions/cart";
import { floatToString } from "../../functions/money";
import * as styles from "./styles.module.scss"
import { useDispatch, useSelector } from 'react-redux'
import Layout from "../../components/layout"
import Counter from "../../components/elements/Counter";

import { useForm } from 'react-hook-form'
import Button from "../../components/elements/Button";
import updateCart from "../../functions/updateCart";
import isLoggedIn, { checkLoggedIn, getUserToken } from "../../services/auth";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { storeFormDataAction } from "../../store/actions";
import TextInput from "../../components/elements/Inputs/TextInput";
import { PayPalButton } from "react-paypal-button-v2";

const Warenkorb = (props) => {
    const checkoutData = useSelector(state => state?.checkout.additionalData);

    const [showContainer, setShowContainer] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [useCustomerCardDiscount, setUseCustomerCardDiscount] = useState(checkoutData?.useCustomerCardDiscount);


    const { register: registerShipping, getValues: getShippingValues, handleSubmit: handleShippingSubmit } = useForm();
    const { register: registerCouponCode, getValues: getCouponCodeValue, handleSubmit: handleCouponCodeSubmit } = useForm();

    const dispatch = useDispatch();
    const cartItems = useSelector(state => state?.cart);

    useEffect(() => {
        refreshCart();
    }, [cartItems, useCustomerCardDiscount]);


    useEffect(() => {
        console.log("checkoutData", checkoutData)
    }, [checkoutData]);


    const refreshCart = () => {

        setLoading(true);

        let items = [];
        cartItems.forEach(item => {
            items.push({
                id: item.id,
                quantity: item.quantity,
                variation_id: item?.variationId || null,
                price: item.price,
            });
        });

        const couponValues = getCouponCodeValue();
        const shippingValues = getShippingValues();

        let data = {
            use_shipping: true,
            cart: items,
        }

        if (checkLoggedIn()) {
            data.token = getUserToken();
        }

        data.use_discount = useCustomerCardDiscount;

        if (couponValues.couponCode !== '' && couponValues.couponCode !== undefined) {
            data.coupon = couponValues.couponCode.replace('-', '');
        }

        if (
            shippingValues.address !== '' &&
            shippingValues.postcode !== '' &&
            shippingValues.city !== ''
        ) {
            data.shipping_destination = {
                address: shippingValues.address,
                postcode: shippingValues.postcode,
                city: shippingValues.city,
            }
        }

        updateCart(data).then(res => {

            console.log("cartResponse", res);

            let additionalData = checkoutData;

            additionalData.sumNet = res.subtotal;
            additionalData.sum = res.total;
            additionalData.tax = res.subtotal_tax;

            additionalData.useCustomerCardDiscount = useCustomerCardDiscount;

            additionalData.couponCode = couponValues.couponCode;
            additionalData.couponTotal = res.coupon_total;
            additionalData.couponRest = res.coupon_rest;

            additionalData.customerCardDiscount = res.customer_card_discount;
            additionalData.customerCardDiscountUsed = res.customer_card_discount_used;

            // if (res.shipping_costs !== undefined) {
            additionalData.shipping = res.shipping_costs;
            // }

            dispatch(storeFormDataAction({ additionalData: additionalData }));

            setLoading(false);
        })

    }


    const setCouponCode = (data) => {
        refreshCart();
    }

    const changeUseCustomerCardDiscount = () => {
        setUseCustomerCardDiscount(prev => !prev);
    }

    const renderShippingMethod = () => {
        if (checkoutData?.shipping?.price !== undefined) {
            return `Standardversand ca. ${checkoutData?.shipping.distance} km: € ${floatToString(checkoutData?.shipping.price)}`;
        }

        return `Abholung im Laden`;
    }


    return (
        <Layout>
            <div className={`container`} style={{ paddingTop: 100, paddingBottom: 100 }}>
                <div className={`col12`}>
                    <div>
                        <h1>Warenkorb</h1>
                        <div
                            className={`${styles.container}`}
                        >
                            <div className={`${styles.item} ${styles.header}`}>
                                <div className={styles.imageContainer}>
                                    <div className={styles.label}>Produkt</div>
                                </div>
                                <div className={styles.contentContainer}></div>
                                <div className={styles.priceContainer}>
                                    <div className={styles.label}>Preis</div>
                                </div>
                                <div className={styles.quantityContainer}>
                                    <div className={styles.label}>Menge</div>
                                </div>
                                <div className={styles.sumContainer}>
                                    <div className={styles.label}>Gesamt</div>
                                </div>
                            </div>
                            {
                                cartItems && cartItems.map(item => {

                                    let price = item.price;
                                    price = price * item.quantity;

                                    return <div className={styles.item}>
                                        <div className={styles.imageContainer}>
                                            <div className={styles.image} style={{ backgroundImage: `url(${item.image})` }}></div>
                                        </div>
                                        <div className={styles.contentContainer}>
                                            <h4 className={styles.title}>{item.title}</h4>
                                            <div>{item.detail}</div>
                                            {item.additionalData !== undefined && item.additionalData.textField !== undefined && item.additionalData.textField !== '' ? <p style={{marginTop: 3}}>Text: {item.additionalData.textField}</p> : <></>}

                                            <div className={styles.controlsContainer}>
                                                <div className={styles.action} style={{ marginRight: 20 }} onClick={() => removeFromCart(item.id, dispatch)}>entfernen</div>
                                                <div className={styles.action}>Zu Favoriten hinzufügen</div>
                                            </div>
                                        </div>
                                        <div className={styles.priceContainer}>
                                            € {floatToString(item.price)}
                                        </div>
                                        <div className={styles.quantityContainer}>
                                            {!item.isCoupon ? <Counter value={item.quantity} onChange={(newQuantity) => { changeQuantityOfItem(item.id, newQuantity, dispatch) }} /> : item.quantity}
                                        </div>
                                        <div className={styles.sumContainer}>
                                            € {floatToString(item.price * item.quantity)}
                                        </div>

                                    </div>
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className={`container`}>
                <div className={`col5`}>
                </div>
                <div className={`col7`}>
                    <div>
                        <table className={styles.table}>
                            <tr>
                                <td></td>
                                <td style={{ paddingBottom: 50 }}>
                                    <form onSubmit={handleCouponCodeSubmit(setCouponCode)}>
                                        <TextInput
                                            type="coupon"
                                            required
                                            {...registerCouponCode('couponCode')}
                                            label="Gutscheincode"
                                        />

                                        <input type="submit" className={"simpleButton"} value="Aktualiseren" />
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ color: 'var(--black-color)' }}>Zwischensumme</td>
                                <td style={{ color: 'var(--black-color)' }}>€ {floatToString(checkoutData?.sumNet)}</td>
                            </tr>
                            <tr>
                                <td style={{ color: 'var(--black-color)' }}>davon Mehrwertsteuer</td>
                                <td style={{ color: 'var(--black-color)' }}>€ {floatToString(checkoutData?.tax)}</td>
                            </tr>
                            {checkoutData?.customerCardDiscount > -1 && checkLoggedIn() &&
                                <tr>
                                    <td style={{ color: 'var(--black-color)' }}>Kundenkarten-Guthaben</td>
                                    <td style={{ color: 'var(--black-color)' }}>€ {floatToString(checkoutData?.customerCardDiscount)} <div onClick={changeUseCustomerCardDiscount} className="simpleButton" style={{ marginLeft: 10 }}>{!checkoutData?.useCustomerCardDiscount ? 'verwenden' : 'nicht verwenden'}</div></td>
                                </tr>
                            }
                            {checkoutData?.couponTotal > 0 &&
                                <tr>
                                    <td style={{ color: 'var(--black-color)' }}>Gutschein</td>
                                    <td style={{ color: 'var(--black-color)' }}>€ {floatToString(checkoutData?.couponTotal)} {checkoutData?.couponRest > 0 && `(Restbetrag: € ${floatToString(checkoutData?.couponRest)})`}</td>
                                </tr>
                            }
                            <tr>
                                <td style={{ color: 'var(--black-color)' }}>Versand</td>
                                <td style={{ paddingBottom: 60 }}>
                                    <div style={{ color: 'var(--black-color)' }}>{renderShippingMethod()}</div>
                                    <p>
                                        Versandoptionen werden während des Bezahlvorgangs aktualisiert
                                    </p>

                                    <br />

                                    <div style={{ color: 'var(--black-color)' }}>Versandkosten berechnen</div>
                                    <form onSubmit={handleShippingSubmit(refreshCart)}>
                                        <TextInput
                                            type="text"
                                            required
                                            {...registerShipping('address')}
                                            label="Adresse"
                                        />
                                        <TextInput
                                            type="text"
                                            required
                                            {...registerShipping('postcode')}
                                            label="Postleitzahl"
                                        />
                                        <TextInput
                                            type="text"
                                            required
                                            {...registerShipping('city')}
                                            label="Ort / Stadt"
                                        />
                                        <input type="submit" className={"simpleButton"} value="Aktualiseren" />
                                    </form>
                                </td>
                            </tr>
                            <tr style={{ textTransform: 'uppercase', color: 'var(--black-color)' }}>
                                <td>Gesamtsumme</td>
                                <td>€ {floatToString(checkoutData?.sum)}</td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>

                                    <PayPalButton
                                        options={{
                                            clientId: "AaffetIKBXtlpu6myFSYZEgXuAnCp47cM1XW2CsVoQOGiTQFbCI4Mrzju0GdsUSyPlhuqZDmGu5DSv2G",
                                            currency: "EUR",
                                            // disableFunding: true,
                                            // disableCard: true,
                                        }}
                                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [{
                                                    amount: {
                                                        value: checkoutData?.sum,
                                                        currency: 'EUR'
                                                    }
                                                }]
                                            });
                                        }}
                                        onShippingChange={(data, actions) => {
                                            console.log("PAYPAL ADDRESS", data)

                                            // return fetch('/my-backend/service', {
                                            //     method: 'post',
                                            //     headers: {
                                            //         'content-type': 'application/json'
                                            //     },
                                            //     body: JSON.stringify(data)
                                            // }).then(res => {
                                            //     if (!res || res.error) {__checkout	{"currentStep":"login","billing":{"first_name":"Lukas","last_name":"Greul","address_1":"Karlhofstraße 6","address_2":"","city":"Linz","postcode":"4040","country":"","state":"","email":"lukas.greul@me.com","phone":"+43 676 7750821"},"shipping":{"shippingType":"billingAddress","shippingTotal":10,"first_name":"Lukas","last_name":"Greul","address_1":"Karlhofstraße 6","address_2":"","city":"Linz","postcode":"4040","type":"on","shippingCheck":20},"payment":{"paymentType":"sepa__pm_1JUUg1GJ1lhugf3LkFGc4J1A"},"register":{"wantRegister":false,"email":"","password":""},"additionalData":{"useCustomerCardDiscount":false}}
                                            //         return actions.reject();
                                            //     }

                                            //     return actions.resolve();
                                            // });


                                        }}
                                    />
                                    <Button
                                        type="primary"
                                        destination="/kasse"
                                        title="Zur Kasse"
                                        additionalStyle={{ width: '100%' }}
                                    />
                                </td>
                            </tr>
                        </table>

                    </div>
                    <LoadingSpinner type="wholeContainerTransparent" active={loading} />
                </div>
            </div>
        </Layout >

    )
}

export default Warenkorb