import React, { useEffect, useRef, useState } from "react"
import * as styles from "./styles.module.scss"

const Counter = ({ onChange, value, max, min }) => {

    if (min === undefined) {
        min = 1;
    }

    return (
        <div className={styles.container}>
            <div
                className={`${styles.control} ${styles.left}`}
                onClick={() => {
                    if (value > min) {
                        onChange(parseInt(value) - 1)
                    }
                }}
            ></div>
            <input type="number" value={value} onChange={(val) => {
                let v = val.target.value;
                if (v === "") {
                    onChange(v)
                }
                else if (isNaN(v) && v !== "") {
                    onChange(min);
                }
                else if (v < min) {
                    onChange(min);
                }
                else if (max !== undefined && v > max) {
                    onChange(max);
                } else {
                    onChange(v)
                }
            }} onBlur={() => {
                if (value === "") {
                    onChange(min);
                }
            }} className={`${styles.value}`} />
            <div
                className={`${styles.control} ${styles.right}`}
                onClick={() => {
                    if (max !== undefined && value > (max - 1)) {
                        return
                    }
                    onChange(parseInt(value) + 1)
                }}
            ></div>
        </div>
    )
}

export default Counter;